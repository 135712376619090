/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: pengedarseni (https://sketchfab.com/pengedarseni)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/3d-github-logo-441d03d1076b44f483df551e02d970fe
Title: 3D Github Logo
*/

import React, { useRef } from "react";
import { Float, useGLTF } from "@react-three/drei";

export default function GithubLogo(props) {
  const { nodes, materials } = useGLTF("/models/3d_github_logo.glb");
  return (
    <Float floatIntensity={2}>
      <group position={[8, 8, 0]} {...props} scale={0.18}>
        <group
          position={[-0.055, 2.945, 6.336]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={28.364}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Object_4.geometry}
            material={materials.glossy_putih}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Object_5.geometry}
            material={materials.github}
          />
        </group>
      </group>
    </Float>
  );
}

useGLTF.preload("/models/3d_github_logo.glb");
