export const navLinks = [
  {
    id: 1,
    name: "Home",
    href: "#home",
  },
  {
    id: 2,
    name: "About",
    href: "#about",
  },
  {
    id: 3,
    name: "Work",
    href: "#work",
  },
  {
    id: 4,
    name: "Contact",
    href: "#contact",
  },
];

export const myProjects = [
  {
    title: "Chunnel and Channel Portal",
    desc: "Chunnel and Channel Portal is an innovative platform tailored for real estate sellers, providing a seamless way to track commissions on property sales. Designed to support real estate marketing companies, it empowers sellers with real-time insights into their earnings and sales performance.",
    subdesc:
      "Built as an online system with Next.js 14, Tailwind CSS, TypeScript, Material UI and ShadCN components. Chunnel and Channel Portal is designed for optimal performance and scalability.",
    href: "http://chunnelandchannel.com/",
    texture: "/textures/project/project1.mp4",
    logo: "/assets/cnc_logo.png",
    logoStyle: {
      backgroundColor: "#2A1816",
      border: "0.2px solid #36201D",
      boxShadow: "0px 0px 60px 0px #1679AB",
    },
    spotlight: "/assets/spotlight2.png",
    tags: [
      {
        id: 1,
        name: "Next.js",
        path: "/assets/nextjs.svg",
      },
      {
        id: 2,
        name: "React.js",
        path: "/assets/react.svg",
      },
      {
        id: 3,
        name: "TailwindCSS",
        path: "assets/tailwindcss.png",
      },
      {
        id: 4,
        name: "TypeScript",
        path: "/assets/typescript.png",
      },
      {
        id: 5,
        name: "MongoDB",
        path: "/assets/mongodb.svg",
      },
      {
        id: 6,
        name: "Firebase",
        path: "/assets/firebase.svg",
      },
    ],
  },
  {
    title: "520 IT Services Landing Page",
    desc: "520 IT Services is a landing page where people across the philippines can see and inquire about the best services provided. Your I.T related needs are solved with one contact away.",
    subdesc:
      "Built as a landing page with React.js, Tailwind CSS, and Framer Motion. 520 IT Services Landing Page is designed for optimal performance and scalability.",
    href: "https://www.520itservices.com/",
    texture: "/textures/project/project2.mp4",
    logo: "/assets/fivetwenty.svg",
    logoStyle: {
      backgroundColor: "#13202F",
      border: "0.2px solid #17293E",
      boxShadow: "0px 0px 60px 0px #185519",
    },
    spotlight: "/assets/spotlight3.png",
    tags: [
      {
        id: 1,
        name: "React.js",
        path: "/assets/react.svg",
      },
      {
        id: 2,
        name: "TailwindCSS",
        path: "assets/tailwindcss.png",
      },
      {
        id: 3,
        name: "TypeScript",
        path: "/assets/typescript.png",
      },
      {
        id: 4,
        name: "Framer Motion",
        path: "/assets/framer.png",
      },
      {
        id: 5,
        name: "Firebase",
        path: "/assets/firebase.svg",
      },
    ],
  },
  {
    title: "Asia's Golden Icon Awards",
    desc: "Asia's Golden Icon Awards is a distinguished platform celebrating individual achievers and successful businesses that make a meaningful impact in their fields and communities. The website provides an engaging experience, allowing users to nominate awardees With a nomination feature, visitors can easily submit candidates who exemplify excellence, ensuring deserving individuals and companies gain recognition in their industries.",
    subdesc:
      "Built as a nomination platform with Next.js, MongoDB, Tailwind CSS, and Framer Motion. Asia's Golden Icon Awards Website is designed for optimal performance and scalability.",
    href: "https://asiasgoldeniconsawards.com/",
    texture: "/textures/project/project3.mp4",
    logo: "/assets/agialogo.png",
    logoStyle: {
      backgroundColor: "#13202F",
      border: "0.2px solid #17293E",
      boxShadow: "0px 0px 60px 0px #A35709",
    },
    spotlight: "/assets/spotlight1.png",
    tags: [
      {
        id: 1,
        name: "Next.js",
        path: "/assets/nextjs.svg",
      },
      {
        id: 2,
        name: "React.js",
        path: "/assets/react.svg",
      },
      {
        id: 3,
        name: "TailwindCSS",
        path: "assets/tailwindcss.png",
      },
      {
        id: 4,
        name: "TypeScript",
        path: "/assets/typescript.png",
      },
      {
        id: 5,
        name: "MongoDB",
        path: "/assets/mongodb.svg",
      },
      {
        id: 6,
        name: "Framer Motion",
        path: "/assets/framer.png",
      },
      {
        id: 7,
        name: "Firebase",
        path: "/assets/firebase.svg",
      },
    ],
  },
  {
    title: "LPU Equipment Information System",
    desc: "The LPU Equipment Information System (EIS) streamlines the process of inspecting and approving electronics and other materials for campus use. Previously, students bringing items like laptops, guitars, or cooking equipment had to visit the inspection office, leading to delays with high student volume. Now, with an online system, students simply upload images of their items for approval. Once approved, they receive an RFID sticker, enabling quick scans upon campus entry and exit, enhancing both efficiency and security. Only enrolled students and authorized staff can use the system, ensuring controlled access.",
    subdesc:
      "Developed as a capstone project using PHP, MySQL, Javascript, and Bootstrap for UI, the LPU EIS is designed for scalability and high performance, offering a seamless and secure inspection experience for students and university staff.",
    href: "",
    texture: "/textures/project/project4.mp4",
    logo: "/assets/eis.png",
    logoStyle: {
      backgroundColor: "#13202F",
      border: "0.2px solid #17293E",
      boxShadow: "0px 0px 60px 0px #A35709",
    },
    spotlight: "/assets/spotlight1.png",
    tags: [
      {
        id: 1,
        name: "PHP",
        path: "/assets/php.svg",
      },
      {
        id: 2,
        name: "Bootstrap",
        path: "assets/bootstrap.svg",
      },
      {
        id: 3,
        name: "JavaScript",
        path: "/assets/javascript.svg",
      },
      {
        id: 4,
        name: "MySQL",
        path: "/assets/mysql.svg",
      },
    ],
  },
];

// export const calculateSizes = (isSmall, isMobile, isTablet) => {
//   return {
//     deskScale: isSmall ? 0.05 : isMobile ? 0.06 : 0.065,
//     deskPosition: isMobile ? [0.5, -4.5, 0] : [0.25, -5.5, 0],
//     cubePosition: isSmall
//       ? [4, -5, 0]
//       : isMobile
//       ? [5, -5, 0]
//       : isTablet
//       ? [5, -5, 0]
//       : [9, -5.5, 0],
//     reactLogoPosition: isSmall
//       ? [3, 4, 0]
//       : isMobile
//       ? [5, 4, 0]
//       : isTablet
//       ? [5, 4, 0]
//       : [12, 3, 0],
//     ringPosition: isSmall
//       ? [-5, 7, 0]
//       : isMobile
//       ? [-10, 10, 0]
//       : isTablet
//       ? [-12, 10, 0]
//       : [-24, 10, 0],
//     targetPosition: isSmall
//       ? [-5, -10, -10]
//       : isMobile
//       ? [-9, -10, -10]
//       : isTablet
//       ? [-11, -7, -10]
//       : [-13, -13, -10],
//   };
// };

export const workExperiences = [
  {
    id: 1,
    name: "Chunnel and Channel Realty Marketing and Development Corporation · Full-time",
    pos: "Full-stack Developer",
    duration: "2024 - Present",
    title: [
      "Developed a comprehensive portal for a real estate marketing company using Next.js as a full-stack framework.",
      "Designed an admin interface for sellers to track commissions, enhancing transparency and user experience.",
      "Manage and maintain websites for sister companies, ensuring seamless functionality and brand consistency.",
      "Strengthened full-stack development skills through designing scalable, user-centered solutions.",
      "Gained experience in managing multiple projects concurrently, ensuring timely delivery and high-quality standards.",
    ],

    icon: "/assets/cnc_logo.png",
    animation: "victory",
  },
  {
    id: 2,
    name: "Woflow · Part-time",
    pos: "Data Specialist",
    duration: "2023",
    title: [
      "Responsible for encoding product data with precision to support business needs.",
      "Ensured data accuracy, emphasizing the importance of meticulous attention to detail and data integrity.",
      "Collaborated with cross-functional teams, developing a disciplined approach to managing large datasets.",
      "Strengthened foundational skills in handling data and maintaining quality standards in software development.",
      "Gained valuable experience in contributing to data management tasks with efficiency and accuracy.",
    ],

    icon: "/assets/woflow.png",
    animation: "clapping",
  },
  {
    id: 3,
    name: "Five Twenty I.T Services - Internship",
    pos: "I.T Technical Support",
    duration: "2022",
    title: [
      "Provided technical support and troubleshooting assistance to clients for software and hardware issues.",
      "Performed system maintenance to ensure smooth operation and minimal downtime.",
      "Gained hands-on experience in diagnosing issues, optimizing workflows, and managing IT infrastructure.",
      "Developed strong problem-solving skills and adaptability through real-time debugging and issue resolution.",
      "Enhanced technical understanding by working closely with various systems and resolving diverse technical challenges.",
    ],
    icon: "/assets/fivetwenty.svg",
    animation: "salute",
  },
];
