import { Canvas } from "@react-three/fiber";
import React, { Suspense, useState } from "react";
import { workExperiences } from "../constants";
import { OrbitControls } from "@react-three/drei";
import CanvasLoader from "../components/CanvasLoader";
import { motion } from "framer-motion";

import Myself from "../components/Myself";

const Experience = () => {
  const [animationName, setAnimationName] = useState("idle");
  return (
    <motion.div
      id="experience"
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: -100 }}
      transition={{ duration: 1, delay: 0.2, ease: "easeInOut" }}
      viewport={{ once: true }}
      className="c-space my-20 max-w-[1500px] mx-auto"
    >
      <div className="w-full text-white-600">
        <h3 className="head-text">My Work Experience</h3>
        <div className="work-container">
          <div className="work-canvas">
            <Canvas>
              <ambientLight intensity={1} />

              <directionalLight
                intensity={2}
                position={[10, 10, 10]}
                color={0xffffff}
              />
              {/* <Developer
                position-y={-3}
                scale={3}
                animationName={animationName}
              /> */}
              <Myself position-y={-3} scale={3} animationName={animationName} />
              <OrbitControls enableZoom={false} maxPolarAngle={Math.PI / 2} />
              <Suspense fallback={<CanvasLoader />}></Suspense>
            </Canvas>
          </div>
          <div className="work-content">
            <div className="sm:py-10 py-5 sm:px-5 px-2.5">
              {workExperiences.map(
                (
                  { id, name, pos, icon, duration, title, animation },
                  index
                ) => (
                  <div
                    key={id}
                    className="work-content_container group"
                    onClick={() => setAnimationName(animation.toLowerCase())}
                    onPointerOver={() =>
                      setAnimationName(animation.toLowerCase())
                    }
                    onPointerOut={() => setAnimationName("idle")}
                  >
                    <div className="flex flex-col h-full justify-start items-center py-2">
                      <div className="work-content_logo">
                        <img
                          src={icon}
                          alt="logo"
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <div className="work-content_bar" />
                    </div>
                    <div className="sm:p-5 px-2.5 py-5">
                      <p className="font-bold text-white-800">{name}</p>
                      <p className="text-sm mb-5">
                        {pos} -- {duration}
                      </p>
                      <ul className="list-disc">
                        {title.map((task_title) => (
                          <li className="group-hover:text-white transition ease-out duration-500">
                            {task_title}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
