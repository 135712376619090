import { Canvas } from "@react-three/fiber";

// import { Leva, useControls } from "leva";
import Myself from "../components/Myself";
import Button from "../components/Button";
import ReactLogo from "../components/ReactLogo";
import PythonLogo from "../components/PythonLogo";
import GithubLogo from "../components/GithubLogo";
import { Suspense } from "react";
import CanvasLoader from "../components/CanvasLoader";

export default function Hero() {
  // const controls = useControls("Myself", {
  //   positionX: {
  //     value: 1,
  //     min: -20,
  //     max: 20,
  //   },
  //   positionY: {
  //     value: 2.5,
  //     min: -10,
  //     max: 10,
  //   },
  //   positionZ: {
  //     value: 2.5,
  //     min: -10,
  //     max: 10,
  //   },
  //   rotationX: {
  //     value: 2.5,
  //     min: -10,
  //     max: 10,
  //   },
  //   rotationY: {
  //     value: 2.5,
  //     min: -10,
  //     max: 10,
  //   },
  //   rotationZ: {
  //     value: 2.5,
  //     min: -10,
  //     max: 10,
  //   },
  //   scale: { value: 2.5, min: -10, max: 10 },
  // });
  return (
    <section
      id="home"
      className="w-full flex flex-col lg:flex-row min-h-screen mx-auto bg-[#010103]"
    >
      <div className="w-full lg:w-6/12 px-16 lg:px-20 xl:px-28  h-screen flex flex-col gap-2 items-center justify-center ">
        <div>
          {" "}
          <p className="sm:text-3xl text-2xl font-medium text-white text-center font-generalsans">
            Hi, I am Jonas <span className="waving-hand">👋</span>
          </p>
        </div>

        <div>
          <p className="hero_tag text-gray_gradient">
            Creating solutions to your business needs.
          </p>
        </div>
        <div className="w-full flex justify-center">
          <a href="#about" className="w-fit">
            <Button
              className="btn"
              isBeam
              name="Let's Work Together"
              containerClass={
                "bg-transparent border border-white hover:bg-white hover:text-black px-6 py-3"
              }
            />
          </a>
        </div>
      </div>
      <div className="w-full lg:w-6/12 h-screen bg-radial-gradient ">
        <div className="w-full h-full">
          {" "}
          {/* <Leva /> */}
          <Canvas className="w-full  inset-0">
            <ambientLight />
            <directionalLight
              intensity={2}
              position={[10, 10, 10]}
              color={0xffffff}
            />

            <Myself
              // position={[
              //   controls.positionX,
              //   controls.positionY,
              //   controls.positionZ,
              // ]}
              // rotation={[
              //   controls.rotationX,
              //   controls.rotationY,
              //   controls.rotationZ,
              // ]}
              // scale={controls.scale}
              position={[0.2, -10.1, -3.5]}
              rotation={[-3.3, -3.1, -3.15]}
              scale={7.7}
            />

            <group>
              <ReactLogo position={[1.2, 1, 1]} />
              <PythonLogo position={[-1, 1, 1]} />
              <GithubLogo position={[1, -1, 1]} />
            </group>
          </Canvas>
        </div>
      </div>
    </section>
  );
}
