import Navbar from "./components/Navbar";
import About from "./sections/About";
import Contact from "./sections/Contact";
import Experience from "./sections/Experience";
import Footer from "./sections/Footer";
import Hero from "./sections/Hero";
import Project from "./sections/Project";

// Load environment variables from .env file

function App() {
  return (
    <main className="max-w-[1920px]mx-auto h-full bg-[#010103d7]">
      <Navbar />
      <Hero />
      <About />
      <Project />
      <Experience />
      <Contact />
      <Footer />
    </main>
  );
}

export default App;
