import React from "react";

const Footer = () => {
  return (
    <section className=" max-w-[1440px] mx-auto c-space pt-7 pb-3 border-t border-black-300 flex justify-between items-center flex-wrap gap-5">
      <div className="flex gap-3">
        <a href="https://github.com/jonasdvsev">
          <div className="social-icon">
            <img
              src="/assets/github.svg"
              alt="github"
              className="w-1/2 h-1/2"
            />
          </div>
        </a>
        <a href="https://www.linkedin.com/in/jonassevillano/">
          <div className="social-icon">
            <img
              src="/assets/linkedin.svg"
              alt="github"
              className="w-1/2 h-1/2"
            />
          </div>
        </a>
      </div>
      <p className="text-white-500">
        Copyright 2024 Jonas. All rights reserved.
      </p>
    </section>
  );
};

export default Footer;
